








































import Vue from 'vue';
import Component from 'vue-class-component';
import { LMap, LTileLayer, LMarker, LIcon, LPopup } from 'vue2-leaflet';
import BlockHeader from '@/pages/Main/Section.header.vue';
import L from 'leaflet';
import MapService, {IMarkerTarget, ICenter} from '@/services/data/map.service';

@Component({
  components: {
    BlockHeader,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LPopup
  }
})
export default class Map extends Vue {
  public $refs!: {
    myMap: LMap
  };

  mounted() {
      this.$nextTick(() => {
        this.$refs.myMap.mapObject.scrollWheelZoom.disable();
    });
  }

  mapSettings = MapService.getMapConfigs();
  markerSettings = this.mapSettings.markerSettings;

  myIconActive = L.icon({
      ...this.markerSettings,
      iconUrl: require('@/assets/image/marker-active.png'),
  });

  myDefaultIcon = L.icon({
      iconUrl: require('@/assets/image/marker-default.png'),
      ...this.markerSettings
  });

  private _previousTargetClicked: any;

  onMouseEvent = (event: IMarkerTarget) => {
    if (!this._previousTargetClicked) {
      this._previousTargetClicked = event.target;
      event.target.setIcon(this.myIconActive);
    } else {
      if (this._previousTargetClicked._leaflet_id !== event.target._leaflet_id) {
        this._previousTargetClicked.setIcon(this.myDefaultIcon);
        this._previousTargetClicked = event.target;
        event.target.setIcon(this.myIconActive);
      }
    }
  }

  clearAll = (event: Event) => {
    if (event.type === 'click' && this._previousTargetClicked != null) {
      this._previousTargetClicked.setIcon(this.myDefaultIcon);
      this._previousTargetClicked.closePopup();
      this._previousTargetClicked = null;
    }
  }

  setProtocol = (center: ICenter): string => {
    return (center.protocol === 'http'
      ? 'http://'
      : 'https://')
      + center.website;
  }
}
