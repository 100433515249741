
















import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop} from 'vue-property-decorator';
import {IFunctionalityData} from '@/services/data/functionality.service';

@Component({})
export default class AccordionItem extends Vue {

  @Prop({
    type: Object,
    required: true,
    default: null
  })
  public item!: IFunctionalityData;

  @Prop({
    type: Number,
    required: false,
    default: 1
  })
  public activeAccordionId!: number;

  onToggle() {
    this.$emit('onToggle', this.item);
  }

  public get isActive(): boolean {
    return this.activeAccordionId === this.item.id;
  }

  public get isImageNeeded(): boolean {
    return !!((this.$store.state.ui.adaptiveMode === 'SM' || this.$store.state.ui.adaptiveMode === 'XS') && this.item.image);
  }
}
