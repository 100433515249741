import store from '@/store';
import { latLngBounds } from 'leaflet';

export interface ICenter {
  id: number;
  MZ: string;
  city: string;
  coordinates: number[];
  zip: number;
  website: string;
  protocol?: string;
  phone: string;
  fax: string | null;
  strasse: string;
}

export interface IMarkerTarget {
  target: {
    openPopup(): () => void;
    closePopup(): () => void;
    setIcon(settings: object): () => void;
    _leaflet_id: number;
  };
}

export interface IDefaultMarkerSettings {
  iconAnchor: any;
  iconSize: any;
  popupAnchor: any;
} // TODO update interface

export interface IMapState {
  baseUrl: string;
  center: number[];
  centres: ICenter[];
  zoom: number;
  minZoom: number;
  maxZoom: number;
  markerSettings: IDefaultMarkerSettings;
  maxBounds: any;
}

export interface IMapService {
  getMapConfigs: () => IMapState,
}

const getMapConfigs = (): IMapState => {
  return {
    markerSettings: {
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [-6.5, -32],
    },
    baseUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    center: [48.7758459, 9.1829321],
    zoom: 8,
    minZoom: 8,
    maxZoom: 12,
    maxBounds: store.getters.isMobileAdaptiveMode ? latLngBounds([50.7758459, 6.1829321], [46.7758459, 12.1829321]) : null,
    centres: [
      {
        id: 1,
        MZ: 'Kreismedienzentrum',
        city: 'Aalen',
        coordinates: [48.8234301, 9.984576],
        zip: 73430,
        phone: 'tel:07361/503-327',
        website: 'www.kmz-ostalbkreis.de',
        fax: 'tel:07361/503-326',
        strasse: 'Stuttgarter Str. 41',
      },
      {
        id: 2,
        MZ: 'Kreismedienzentrum',
        city: 'Albstadt',
        coordinates: [48.21388, 9.0279042],
        zip: 72458,
        phone: 'tel:07431/9353080',
        website: 'www.zollernalbkreis.de/landratsamt/aemter++und+organisation/kmz',
        fax: 'tel:07431/9353088',
        strasse: 'Grüngrabenstr. 20',
      },
      {
        id: 3,
        MZ: 'Kreismedienzentrum',
        city: 'Backnang',
        coordinates: [48.9428199999999, 9.42544],
        zip: 71522,
        phone: 'tel:07191/895-4021',
        website: 'www.kreismedienzentrum-rmk.de',
        fax: 'tel:07191/895-4019',
        strasse: 'Erbstetter Straße 58',
      },
      {
        id: 4,
        MZ: 'Kreismedienzentrum',
        city: 'Biberach an der Riß',
        coordinates: [48.095813, 9.7929438],
        zip: 88400,
        phone: 'tel:07351/522-64',
        website: 'www.kmzbc.de/index.php',
        fax: 'tel:07351/524-04',
        strasse: 'Rollinstr. 9',
      },
      {
        id: 5,
        MZ: 'Kreismedienzentrum',
        city: 'Böblingen',
        coordinates: [48.6822999999999, 9.00953999999999],
        zip: 71034,
        phone: 'tel:07031/663-1486',
        website: 'www.medienzentrum-bb.de',
        fax: 'tel:07031/663-1614',
        strasse: 'Parkstr. 16',
      },
      {
        id: 6,
        MZ: 'Kreismedienzentrum',
        city: 'Crailsheim',
        coordinates: [49.1424299999999, 10.07333],
        zip: 74564,
        phone: 'tel:07951/49222',
        website: 'www.kmz-sha.de',
        fax: 'tel:07951/49251',
        strasse: 'In den Kistenwiesen 2/1',
      },
      {
        id: 7,
        MZ: 'Kreismedienzentrum',
        city: 'Ehingen an der Donau',
        coordinates: [48.2822407, 9.7251233],
        zip: 89584,
        phone: 'tel:07391/7025-50',
        website: 'www.kreismedienzentrum-ulm.de',
        fax: 'tel:07391/7025-52',
        strasse: 'Schulgasse 11',
      },
      {
        id: 8,
        MZ: 'Kreismedienzentrum',
        city: 'Plochingen a. N.',
        coordinates: [48.71395, 9.4306],
        zip: 73207,
        phone: 'tel:0711/3902-2327',
        website: 'www.landkreis-esslingen.de/,Lde/start/bildung/kreismedienzentrum.html',
        fax: 'tel:0711/3903-1331',
        strasse: 'Am Aussichtsturm 7',
      },
      {
        id: 9,
        MZ: 'Kreismedienzentrum',
        city: 'Friedrichshafen',
        coordinates: [47.6524, 9.5015],
        zip: 88046,
        phone: 'tel:07541/7003-990',
        website: 'www.kreismedienzentrum-bodenseekreis.de',
        fax: 'tel:07541/7003-993',
        strasse: 'Steinbeisstr. 22-26',
      },
      {
        id: 10,
        MZ: 'Kreismedienzentrum',
        city: 'Göppingen',
        coordinates: [48.7071670999999, 9.6464927],
        zip: 73033,
        phone: 'tel:07161/91424-12',
        website: 'www.kmz-gp.de',
        fax: 'tel:07161/91424-19',
        strasse: 'Eberhardstr. 22',
      },
      {
        id: 11,
        MZ: 'Kreismedienzentrum',
        city: 'Heidenheim a. d. Brenz',
        coordinates: [48.6773422999999, 10.1567266],
        zip: 89522,
        phone: 'tel:07321/321-1652',
        website: 'www.medienzentrum-hdh.de',
        fax: 'tel:07321/321-1660',
        strasse: 'Willy-Brandt-Platz 1',
      },
      {
        id: 12,
        MZ: 'Kreismedienzentrum',
        city: 'Heilbronn',
        coordinates: [49.1372387999999, 9.2275081],
        zip: 74072,
        phone: 'tel:07131/994-411',
        website: 'www.kreismedienzentrum-hn.de',
        fax: 'tel:07131/994-498',
        strasse: 'Lerchenstr. 40',
      },
      {
        id: 13,
        MZ: 'Kreismedienzentrum',
        city: 'Künzelsau',
        coordinates: [49.2774427, 9.6861675],
        zip: 74653,
        phone: 'tel:07940/18-323',
        website: 'www.kmz-hok.de',
        fax: 'tel:07940/18-450',
        strasse: 'Allee 16',
      },
      {
        id: 14,
        MZ: 'Kreismedienzentrum',
        city: 'Leonberg',
        coordinates: [48.80241, 9.00268],
        zip: 71229,
        phone: 'tel:07152/210-53',
        website: 'www.medienzentrum-bb.de',
        fax: 'tel:07152/6332',
        strasse: 'Rutesheimer Str. 50/2B',
      },
      {
        id: 15,
        MZ: 'Kreismedienzentrum',
        city: 'Ludwigsburg',
        coordinates: [48.890304, 9.200964],
        zip: 71638,
        phone: 'tel:07141/144-2249',
        website: 'www.kmz-ludwigsburg.de',
        fax: 'tel:07141/144-2291',
        strasse: 'Hindenburgstr. 40',
      },
      {
        id: 16,
        MZ: 'Kreismedienzentrum',
        city: 'Münsingen',
        coordinates: [48.40755, 9.49958],
        zip: 72525,
        phone: 'tel:07381/9329280',
        website: 'www.kmz-reutlingen.de',
        fax: 'tel:07381/500240',
        strasse: 'Alenbergstr. 27',
      },
      {
        id: 18,
        MZ: 'Kreismedienzentrum',
        city: 'Öhringen',
        coordinates: [49.20685, 9.4981],
        zip: 74613,
        phone: 'tel:07941/3158',
        website: 'www.kmz-hok.de',
        fax: 'tel:07941/8324',
        strasse: 'Sudetenstraße 4',
      },
      {
        id: 19,
        MZ: 'Kreismedienzentrum',
        city: 'Ravensburg',
        coordinates: [47.79704, 9.63161],
        zip: 88212,
        phone: 'tel:0751/368-400',
        website: 'www.kreismedienzentrum.de',
        fax: 'tel:0751/368-418',
        strasse: 'St.-Martinus-Str. 77',
      },
      {
        id: 20,
        MZ: 'Kreismedienzentrum',
        city: 'Reutlingen',
        coordinates: [48.49515, 9.21372],
        zip: 72764,
        phone: 'tel:07121/480-1375',
        website: 'www.kmz-reutlingen.de',
        fax: 'tel:07121/480-1833',
        strasse: 'Kaiserstr. 27',
      },
      {
        id: 21,
        MZ: 'Kreismedienzentrum',
        city: 'Schwäbisch Gmünd',
        coordinates: [48.7932199999999, 9.82067],
        zip: 73525,
        phone: 'tel:07171/323-35',
        website: 'www.kmz-ostalbkreis.de',
        fax: 'tel:07171/323-32',
        strasse: 'Oberbettringer Str. 166',
      },
      {
        id: 22,
        MZ: 'Kreismedienzentrum',
        city: 'Sigmaringen',
        coordinates: [48.0871671, 9.2168426],
        zip: 72488,
        phone: 'tel:07571/6827-20',
        website: 'www.landkreis-sigmaringen.de/de/Landratsamt/Kreisverwaltung/Fachbereiche/Kreismedienzentrum',
        fax: 'tel:07571/6827-22',
        strasse: 'Fürst-Wilhelm-Straße 14',
      },
      {
        id: 23,
        MZ: 'Kreismedienzentrum',
        city: 'Tauberbischofsheim',
        coordinates: [49.6005499999999, 9.68964],
        zip: 97941,
        phone: 'tel:09341/8467-11',
        website: 'www.kmz-tbb.de',
        fax: 'tel:09341/8467-19',
        strasse: 'Flurstr. 2',
      },
      {
        id: 24,
        MZ: 'Kreismedienzentrum',
        city: 'Tübingen',
        coordinates: [48.5193999999999, 9.07863],
        zip: 72072,
        phone: 'tel:07071/978-292',
        website: 'www.kmz-tuebingen.de',
        fax: 'tel:07071/978-204',
        strasse: 'Bismarckstr. 110',
      },
      {
        id: 25,
        MZ: 'Kreismedienzentrum',
        city: 'Ulm',
        coordinates: [48.4016257999999, 10.0042832],
        zip: 89073,
        phone: 'tel:0731/185-1810',
        website: 'www.kreismedienzentrum-ulm.de',
        fax: 'tel:0731/185-1815',
        strasse: 'Basteistr. 46',
      },
      {
        id: 26,
        MZ: 'Kreismedienzentrum',
        city: 'Vaihingen a. d. Enz',
        coordinates: [48.9324, 8.96073],
        zip: 71665,
        phone: 'tel:07042/104-23',
        website: 'www.kmz-vaihingen.de',
        protocol: 'http',
        fax: 'tel:07042/104-24',
        strasse: 'Franckstr. 20',
      },
      {
        id: 27,
        MZ: 'Kreismedienzentrum',
        city: 'Waiblingen',
        coordinates: [48.8234911999999, 9.3022919],
        zip: 71332,
        phone: 'tel:07151/501-1510',
        website: 'www.kreismedienzentrum-rmk.de',
        fax: 'tel:07151/501-1591',
        strasse: 'Stuttgarter Str. 104/1',
      },
      {
        id: 28,
        MZ: 'Stadtmediezentrum',
        city: 'Stuttgart',
        coordinates: [48.7878328, 9.2096899],
        zip: 70180,
        phone: 'tel:0711 2850-719',
        website: 'www.smz-stuttgart.de',
        fax: null,
        strasse: 'Rotenbergstr. 111',
      },
      {
        id: 29,
        MZ: 'Kreismedienzentrum',
        city: 'Calw',
        coordinates: [48.7128299999999, 8.73268],
        zip: 75365,
        phone: 'tel:07051/965-220',
        website: 'kmz-calw.de',
        fax: 'tel:07051/965-230',
        strasse: 'Oberriedter Str. 10',
      },
      {
        id: 30,
        MZ: 'Kreismedienzentrum',
        city: 'Freiburg',
        coordinates: [47.9994993999999, 7.8291106],
        zip: 79114,
        phone: 'tel:0761/278079',
        website: 'www.kmz-freiburg.de',
        fax: 'tel:0761/278081',
        strasse: 'Bissierstr. 17',
      },
      {
        id: 31,
        MZ: 'Kreismedienzentrum',
        city: 'Freudenstadt',
        coordinates: [48.4616928, 8.4153984],
        zip: 72250,
        phone: 'tel:07441/920-1370',
        website: 'www.kmz-fds.de',
        fax: 'tel:07441/920-1379',
        strasse: 'Landhausstr. 4',
      },
      {
        id: 32,
        MZ: 'Kreismedienzentrum',
        city: 'Horb am Neckar',
        coordinates: [48.4412099999999, 8.67789999999999],
        zip: 72160,
        phone: 'tel:07451/907-1381',
        website: 'www.kmz-fds.de',
        fax: 'tel:07451/907-1389',
        strasse: 'Ihlinger Str. 79',
      },
      {
        id: 33,
        MZ: 'Kreismedienzentrum',
        city: 'Heidelberg',
        coordinates: [49.405687, 8.68410639999999],
        zip: 69115,
        phone: 'tel:06221/522-1540',
        website: 'www.mzhd.de',
        fax: 'tel:06221/522-1577',
        strasse: 'Kurfürstenanlage 38-40',
      },
      {
        id: 34,
        MZ: 'Kreismedienzentrum',
        city: 'Bruchsal',
        coordinates: [49.12401, 8.58743],
        zip: 76646,
        phone: 'tel:07251/783-151',
        website: 'www.kmz-lkr-ka.de',
        fax: 'tel:07251/783-267',
        strasse: 'Am alten Güterbahnhof 9',
      },
      {
        id: 35,
        MZ: 'Kreismedienzentrum',
        city: 'Ettlingen',
        coordinates: [48.9404, 8.39682],
        zip: 76275,
        phone: 'tel:07243/500-751',
        website: 'www.kmz-lkr-ka.de',
        fax: 'tel:07243/500-755',
        strasse: 'Beethovenstr. 1',
      },
      {
        id: 36,
        MZ: 'Kreismedienzentrum',
        city: 'Konstanz',
        coordinates: [47.6692299999999, 9.17749],
        zip: 78467,
        phone: 'tel:07531/800-1374',
        website: 'www.lrakn.de/werkstatt/alt+schulen+und+bildung/kreismedienzentrum',
        fax: 'tel:07531/800-1166',
        strasse: 'Benediktinerplatz 1',
      },
      {
        id: 37,
        MZ: 'Kreismedienzentrum',
        city: 'Lörrach',
        coordinates: [47.6217167, 7.6624473],
        zip: 79539,
        phone: 'tel:07621/44916',
        website: 'www.kmz-loerrach.net',
        fax: 'tel:07621/44927',
        strasse: 'Wintersbuckstr. 5',
      },
      {
        id: 38,
        MZ: 'Stadtmedienzentrum',
        city: 'Mannheim',
        coordinates: [49.48636, 8.46558],
        zip: 68161,
        phone: 'tel:0621/293-7920',
        website: 'www.mannheim.de/de/bildung-staerken/stadtmedienzentrum',
        fax: 'tel:0621/293-7925',
        strasse: 'N 1',
      },
      {
        id: 39,
        MZ: 'Kreismedienzentrum',
        city: 'Mosbach',
        coordinates: [49.34806, 9.12867],
        zip: 74821,
        phone: 'tel:06261/84-3520',
        website: 'www.kmz-buchen.de',
        fax: 'tel:06261/84-3521',
        strasse: 'Jean-de-la-Fontaine-Straße 1',
      },
      {
        id: 40,
        MZ: 'Kreismedienzentrum',
        city: 'Offenburg',
        coordinates: [48.4639099999999, 7.94066],
        zip: 77652,
        phone: 'tel:0781/8051-344',
        website: 'www.ortenaukreis.de/Themen/Bildung-/Bildungseinrichtungen-des-Kreises/Kreismedienzentrum',
        fax: 'tel:0781/8051-498',
        strasse: 'Badstr. 20',
      },
      {
        id: 41,
        MZ: 'Kreismedienzentrum',
        city: 'Lahr/Schwarzwald',
        coordinates: [48.33462, 7.85141],
        zip: 77933,
        phone: 'tel:07821/23527',
        website: 'www.ortenaukreis.de/Themen/Bildung-/Bildungseinrichtungen-des-Kreises/Kreismedienzentrum',
        fax: 'tel:07821/39436',
        strasse: 'Tramplerstr. 80',
      },
      {
        id: 42,
        MZ: 'Kreismedienzentrum',
        city: 'Pforzheim',
        coordinates: [48.89087, 8.69564],
        zip: 75172,
        phone: 'tel:07231/308-770',
        website: 'www.enzkreis.de/medienzentrum',
        fax: 'tel:07231/308-777',
        strasse: 'Zerrennerstraße 23 - 25',
      },
      {
        id: 43,
        MZ: 'Kreismedienzentrum',
        city: 'Mittelbaden',
        coordinates: [48.6922199999999, 8.13594999999999],
        zip: 77815,
        phone: 'tel:07223/9814-1420',
        website: 'www.mz-mittelbaden.de',
        fax: 'tel:07223/9814-1429',
        strasse: 'Robert-Koch-Str. 8',
      },
      {
        id: 44,
        MZ: 'Kreismedienzentrum',
        city: 'Rottweil',
        coordinates: [48.15381, 8.62811],
        zip: 78628,
        phone: 'tel:0741/46-161',
        website: 'www.kmz-rw.de',
        fax: 'tel:0741/57634',
        strasse: 'Stadionstraße 5',
      },
      {
        id: 45,
        MZ: 'Kreismedienzentrum',
        city: 'Tuttlingen',
        coordinates: [47.98718, 8.8054],
        zip: 78532,
        phone: 'tel:07461/926-2222',
        website: 'kmz-tuttlingen.de',
        fax: 'tel:07461/926-2290',
        strasse: 'Mühlenweg 17',
      },
      {
        id: 46,
        MZ: 'Kreismedienzentrum',
        city: 'Schwarzwald-Baar',
        coordinates: [48.05944, 8.46777999999999],
        zip: 78048,
        phone: 'tel:07721/913-7200',
        website: 'www.kmz-sbk.de',
        fax: 'tel:07721/913-8920',
        strasse: 'in Am Hoptbühl 2',
      },
      {
        id: 47,
        MZ: 'Kreismedienzentrum',
        city: 'Waldshut-Tiengen',
        coordinates: [47.62275, 8.20485],
        zip: 79761,
        phone: 'tel:07751/86-1323',
        website: 'www.medienzentrum-landkreis-waldshut.de',
        fax: 'tel:07751/86-1398',
        strasse: 'Kaiserstr. 110',
      },
      {
        id: 48,
        MZ: 'Stadtmedienzentrum',
        city: 'Karlsruhe',
        coordinates: [49.01532, 8.37728],
        zip: 76133,
        phone: 'tel:(0721) 8808-0',
        website: 'www.smz-karlsruhe.de',
        fax: 'tel:(0721) 8808-68',
        strasse: 'Moltkestr. 64',
      },
      {
        id: 49,
        MZ: 'Kreismedienzentrum',
        city: 'Buchen (Odenwald)',
        coordinates: [49.523561, 9.330633],
        zip: 74722,
        phone: '',
        website: 'www.kmz-buchen.de',
        fax: null,
        strasse: 'Henry-Dunant-Straße 4',
      },
      {
        id: 50,
        MZ: 'Kreismedienzentrum',
        city: 'Emmendingen',
        coordinates: [48.1240679, 7.854121],
        zip: 79312,
        phone: '',
        website: 'www.landkreis-emmendingen.de/landkreis-politik/einrichtungen-des-kreises/kreismedienzentrum/?L=0',
        fax: null,
        strasse: 'Adolf-Sexauer-Str. 1/1',
      },
      {
        id: 50,
        MZ: 'Kreismedienzentrum',
        city: 'Donaueschingen',
        coordinates: [47.9521523, 8.4979461],
        zip: 78166,
        phone: '',
        website: 'www.kmz-sbk.de',
        fax: null,
        strasse: 'Eichendorffstr. 19',
      },
    ]
  };
};

const MapService :IMapService = {
  getMapConfigs,
};

export default MapService;

