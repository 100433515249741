














import Vue from 'vue';
import Component from 'vue-class-component';
import Slider from '@/components/Slider/Slider.vue';
import {Watch} from 'vue-property-decorator';

@Component({
  components: {
    Slider
  },
  computed: {
    token() {
      return this.$store.getters.getUserField('isAnonymousUser');
    }
  }
})
export default class Head extends Vue {
  @Watch('token')
  private watchHandler() {
    this.getSlideList();
  }

  private created() {
    this.getSlideList();
  }

  private getSlideList() {
    this.$store.dispatch('setSlides');
  }

  public autoPlayConfigs = {
    delay: 5000,
    disableOnInteraction: false
  }
} // TODO do like charts?
