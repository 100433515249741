
































import Vue from 'vue';
import Component from 'vue-class-component';
import SectionHeader from '@/pages/Main/Section.header.vue';
import CallToRegisterService from '@/services/data/call-to-register.service';
import HeaderModals from '@/components/Layout/Header/HeaderModals.vue';
import Slider from '@/components/Slider/Slider.vue';

@Component({
  components: {
    SectionHeader,
    HeaderModals,
    Slider
  }
})
export default class CallToRegister extends Vue {
  public calls = CallToRegisterService.getCallsList();
}
