
















import Vue from 'vue';
import Component from 'vue-class-component';
import Head from '@/pages/Main/Head.section.vue';
import Benefits from '@/pages/Main/Benefits.section.vue';
import Chart from '@/pages/Main/Chart.section.vue';
import Offers from '@/pages/Main/Offers.section.vue';
import Functionality from '@/pages/Main/Functionality.section.vue';
import Map from '@/pages/Main/Map.section.vue';
import CallToRegister from '@/pages/Main/CallToRegister.section.vue';
import Faq from '@/pages/Main/Faq.section.vue';
import CardSlider from '@/pages/Main/Card.slider.section.vue';

@Component({
  components: {
    Benefits,
    Chart,
    Offers,
    Functionality,
    Map,
    CallToRegister,
    Head,
    Faq,
    CardSlider
  }
})
export default class Main extends Vue {}
