export interface IFunctionalityData {
    id: number; 
    title: string;
    description: string;
    image?: string;
}

export interface IFunctionalityService {
    getFuncionalitiesList: () => IFunctionalityData[];
}

const getFuncionalitiesList = (): IFunctionalityData[] => {
    return [
        {
            id: 1,
            title: 'Eigene Merklisten erstellen',
            image: '@/assets/image/Ipad-Iphone.png',
            description: 'Haben Sie etwas Passendes für Ihr Unterrichtsfach gefunden? Speichern Sie es in Ihren persönlichen Merklisten und schaffen Sie sich so einen ganz individuellen Medienpool! Sie können sich merken, so viel Sie wollen, solange Sie wollen.',
        },
        {
            id: 2,
            title: 'Medien flexibel nutzen',
            description: 'Wie möchten Sie die Medien von SESAM nutzen? Manche Medien können Sie in Ihrem Medienzentrum ausleihen. Die meisten Lernangebote stehen Ihnen aber auch online zur Verfügung – zum bequemen Herunterladen oder Streamen – egal, wo sie gerade sind.'
        },
        {
            id: 3,
            title: 'Medien für andere freigeben',
            description: 'Sollen Ihre Schüler zuhause einen Erklärfilm ansehen? Oder möchten Sie in Gruppenarbeit mit unterschiedlichen Filmsequenzen arbeiten? Geben Sie das Unterrichtsmaterial online an Ihre Schüler weiter – ganz einfach über einen QR-Code oder Link. '
        },
        {
            id: 4, 
            title: 'Das Passende finden',
            description: 'Suchen Sie etwas Bestimmtes? Geben Sie gezielt einen Suchbegriff ein! Frei wählbare Filter verfeinern zusätzlich Ihr Suchergebnis. Über die Bildungsstandards können Sie umfassend nach allen Materialien zu Ihrem Unterrichtsfach in SESAM stöbern.'
        }
    ];
};

const FunctionalityService: IFunctionalityService = {
    getFuncionalitiesList
};

export default FunctionalityService;
