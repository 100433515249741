

















import Vue from 'vue';
import Component from 'vue-class-component';
import SectionHeader from '@/pages/Main/Section.header.vue';
import AccordionItem from '@/pages/Main/Accordion.item.vue';
import FunctionalityService, {IFunctionalityData} from '@/services/data/functionality.service';

@Component({
  components: {
    SectionHeader,
    AccordionItem
  }
})
export default class Functionality extends Vue {
  public data: IFunctionalityData[] = FunctionalityService.getFuncionalitiesList();
  activeItem = this.data.find(item => item.id === 1)!;

  onToggle(item: IFunctionalityData) {
    this.activeItem = item;
  }
}
