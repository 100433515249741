import PeopleIcon from '@/assets/icon/benefits/people.svg';
import ThumbsUpIcon from '@/assets/icon/benefits/thumbs-up.svg';
import PaperPlaneIcon from '@/assets/icon/benefits/paper-plane.svg';
import EarthMarkIcon from '@/assets/icon/benefits/earth-mark.svg';

export interface IRotateParameters {
  angle: number;
  x: number;
  y: number;
}

export interface IBenefit {
  translate: string;
  blueTriangleRotateParameters: IRotateParameters;
  whiteTriangleRotateParameters: IRotateParameters;
  icon: object;
  title: string;
  text: string;
}

export interface IBenefitService {
  getBenefitList: () => IBenefit[];
}

const getBenefitList = (): IBenefit[] => [
  {
    translate: '80 -1815',
    blueTriangleRotateParameters: {
      angle: 172,
      x: -27.622,
      y: 971.654
    },
    whiteTriangleRotateParameters: {
      angle: 172,
      x: -27.622,
      y: 971.654
    },
    icon: PeopleIcon,
    title: 'vielseitig',
    text: 'unterschiedliche Medienformate für alle Fächer, Klassenstufen und Schularten'
  },
  {
    translate: '-703 -1828',
    blueTriangleRotateParameters: {
      angle: 40,
      x: -2105.856,
      y: 1952.452
    },
    whiteTriangleRotateParameters: {
      angle: -138,
      x: 781.125,
      y: 859.353
    },
    icon: ThumbsUpIcon,
    title: 'geprüft',
    text: 'von Fachkommissionen der Medienbegutachtung geprüfte, rechtlich sichere Medien'
  },
  {
    translate: '-408 -1828',
    blueTriangleRotateParameters: {
      angle: -22,
      x: 4998.23,
      y: -118.28
    },
    whiteTriangleRotateParameters: {
      angle: 180,
      x: 280.486,
      y: 992.285
    },
    icon: PaperPlaneIcon,
    title: 'einfach',
    text: 'bequemes Suchen, Merken und Teilen von Medien mit der neuen SESAM-Oberfläche'
  },
  {
    translate: '-1003 -1815',
    blueTriangleRotateParameters: {
      angle: -51.98,
      x: 2448.747,
      y: -61.24
    },
    whiteTriangleRotateParameters: {
      angle: 150,
      x: 341.753,
      y: 1126.639
    },
    icon: EarthMarkIcon,
    title: 'flexibel',
    text: 'mit allen digitalen Endgeräten nutzbar – zu Hause, unterwegs oder im Unterricht'
  }
];
const BenefitService: IBenefitService = {
  getBenefitList
};

export default BenefitService;
