import FingerIcon from '@/assets/icon/ico-empfohlen.svg';
import MessagesIcon from '@/assets/icon/ico-messages.svg';
import SmileIcon from '@/assets/icon/ico-smile.svg';

export interface ICall {
  icon: object;
  text: string;
}

export interface ICallService {
  getCallsList: () => ICall[];
}

const getCallsList = (): ICall[] => [
  {
    icon: FingerIcon,
    text: 'Geprüfte Unterrichtsmaterialien'
  },

  {
    icon: MessagesIcon,
    text: 'Für alle Schularten und Altersstufen'
  },

  {
    icon: SmileIcon,
    text: 'Einfach, sicher und vielseitig'
  }
];

const CallToRegisterService: ICallService = {
  getCallsList
};

export default CallToRegisterService;
