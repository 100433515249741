





























import Vue from 'vue';
import Component from 'vue-class-component';
import SectionHeader from '@/pages/Main/Section.header.vue';
import BenefitsImage from '@/pages/Main/Benefits.section.image.vue';
import BenefitService, {IBenefit} from '@/services/data/benefit.service';
import Slider from '@/components/Slider/Slider.vue';

@Component({
  components: {
    SectionHeader,
    BenefitsImage,
    Slider
  }
})
export default class Benefits extends Vue {
  public benefitList: IBenefit[] = BenefitService.getBenefitList();
}
